.specific-requirements {
  width: 100%;
  margin-top: 1.2rem;
  display: block;
}

.specific-requirements__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.5rem;
}

.specific-requirements__text {
  font-size: 0.8rem;
  color: #333;
}

.specific-requirements__button {
  font-size: 0.8rem;
  color: #000;
  border: 0;
  letter-spacing: 0;
  box-shadow: none;
  padding: 0;
  text-transform: none;
  text-decoration: underline;
  background-color: transparent;
  font-family: 'axiformasemibold';
}

.specific-requirements__button:focus {
  outline: 0;
}

@media only screen and (max-width: 479px) {
  .specific-requirements__button {
    font-size: 0.65rem;
    color: #000;
    border: 0;
    letter-spacing: 0;
    box-shadow: none;
    padding: 0;
    text-transform: none;
    text-decoration: underline;
    background-color: transparent;
    font-family: 'axiformasemibold';
  }
}
