.map-address-overlay {
  position: absolute;
  top: 1rem;
  right: 1rem;
  background: rgba(0, 0, 0, 0.7);
  z-index: 20;
  display: block;
  padding: 1rem;
  text-align: left;
}

.map-address-overlay--gatsby {
  color: #ff9e1b;
  background: rgba(0, 0, 0, 0.7);
}

.map-address-overlay__venue {
  font-size: 1.3rem;
  font-family: "axiformasemibold";
  margin-bottom: 0.75rem;
}

@media only screen and (max-width: 479px) {
  .map-address-overlay {
    position: relative;
    top: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.7);
    display: block;
    padding: 1rem;
    text-align: center;
  }
  .map-address-overlay__venue {
    font-size: 1.3rem;
    font-family: "axiformasemibold";
    margin-bottom: 0.75rem;
  }
}
