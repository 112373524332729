.product__table {
  display: flex;
  width: 100%;
  align-items: center;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 1.5rem;
}
.product__subtitle {
  display: block;
  font-size: 1rem;
  color: #666;
}

@media only screen and (max-width: 479px) {
  .product__subtitle {
    text-align: center;
  }
}
