.slider-cover {
  position: absolute;
  z-index: 5;
  width: 100%;
  bottom: 0;
  height: 100%;
  color: #fff;
  font-size: 2.5rem;
  padding-top: 5rem;
  background: -moz-linear-gradient(
    top,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.95) 100%
  ); /* FF3.6-15 */
  background: -webkit-linear-gradient(
    top,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.95) 100%
  ); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.95) 100%
  ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#a6000000',GradientType=0 ); /* IE6-9 */
}

.slider-cover__content {
  position: absolute;
  flex: 1;
  z-index: 10;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 92%;
  bottom: 8%;
  left: 4%;
  line-height: 3rem;
}

.slider-cover__content--gatsby {
  color: #daa520;
}

@media only screen and (max-width: 479px) {
  .slider-cover {
    position: relative;
    padding-top: 0;
    margin-top: 1rem;
    font-size: 1.1rem;
    background: #1f1f1f;
  }
  .slider-cover__content {
    position: relative;
    width: 100%;
    bottom: 0;
    left: 0;
    line-height: 1.5;
  }
}
