.content-card {
  width: 20rem;
  padding: 0 0.5rem;
  margin: 1rem 0;
}
.content-card--whole-line {
  width: 100%;
  display: block;
}

@media only screen and (max-width: 479px) {
  .content-card {
    width: 14rem;
    flex: 1 0 auto;
  }
}
