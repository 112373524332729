.discount-form {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: center;
}

.discount-field {
  flex: 8;
  margin: 0 0.8rem 0 0;
}

.discount-button {
  flex: 1;
  background-color: #ff9e1b;
}

.discount-button--wolf {
  background-color: #0f3e84;
  color: #fff;
}

.discount-form__form-error {
  font-size: 0.8rem;
  padding: 0;
  margin-bottom: 0.5rem;
  color: #ff3323;
  width: 100%;
}
