.full-image-background-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
  position: relative;
  min-height: 42rem;
}

.full-image-background-container--dw {
  background-size: cover;
  overflow: hidden;
  background-repeat: no-repeat;
  background-position: 0% 0%;
}

@media only screen and (min-width: 1000px) and (max-width: 1240px) {
  .full-image-background-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: stretch;
    position: relative;
    min-height: 35rem;
  }
}

@media only screen and (min-width: 828px) and (max-width: 999px) {
  .full-image-background-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: stretch;
    position: relative;
    min-height: 30rem;
  }
}

@media only screen and (min-width: 480px) and (max-width: 827px) {
  .full-image-background-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: stretch;
    position: relative;
    min-height: 26rem;
  }
}

@media only screen and (max-width: 479px) {
  .full-image-background-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: stretch;
    position: relative;
    min-height: 23rem;
  }
  .full-image-background-container--dw {
    background-position: 27% 0%;
  }
}
