.forgot-password-link {
  font-size: 0.7rem;
  color: #666;
  border: 0;
  padding: 0;
  margin: 0;
  background-color: transparent;
  cursor: pointer;
}

.forgot-password-link:hover {
  color: #333;
}
