.payment-form {
  width: 100%;
}
.payment-form__header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 0.75rem;
}
.payment-form__title {
  font-size: 1rem;
  font-family: 'axiformasemibold';
  color: #333;
}

.login-link {
  font-size: 0.8rem;
  color: #ff9e1b;
  border: 0;
  letter-spacing: 0;
  box-shadow: none;
  padding: 0;
  text-transform: none;
  background-color: transparent;
  font-family: 'axiformasemibold';
}

.login-text {
  font-size: 0.8rem;
  margin-right: 0.3rem;
  color: #111;
}
