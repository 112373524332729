.icon {
  margin-right: 0.5rem;
  display: inline-block;
}

.icon--right {
  margin-right: 0;
  margin-left: 0.3rem;
}

.icon--small {
  font-size: 70%;
}

.icon--large {
  font-size: 130%;
}

.icon--no-margin {
  margin: 0;
}

@-webkit-keyframes vertical {
  0% {
    -webkit-transform: translate(0, -3px);
    transform: translate(0, -3px);
  }

  4% {
    -webkit-transform: translate(0, 3px);
    transform: translate(0, 3px);
  }

  8% {
    -webkit-transform: translate(0, -3px);
    transform: translate(0, -3px);
  }

  12% {
    -webkit-transform: translate(0, 3px);
    transform: translate(0, 3px);
  }

  16% {
    -webkit-transform: translate(0, -3px);
    transform: translate(0, -3px);
  }

  20% {
    -webkit-transform: translate(0, 3px);
    transform: translate(0, 3px);
  }

  22% {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0);
  }
}

@keyframes vertical {
  0% {
    -webkit-transform: translate(0, -3px);
    -ms-transform: translate(0, -3px);
    transform: translate(0, -3px);
  }

  4% {
    -webkit-transform: translate(0, 3px);
    -ms-transform: translate(0, 3px);
    transform: translate(0, 3px);
  }

  8% {
    -webkit-transform: translate(0, -3px);
    -ms-transform: translate(0, -3px);
    transform: translate(0, -3px);
  }

  12% {
    -webkit-transform: translate(0, 3px);
    -ms-transform: translate(0, 3px);
    transform: translate(0, 3px);
  }

  16% {
    -webkit-transform: translate(0, -3px);
    -ms-transform: translate(0, -3px);
    transform: translate(0, -3px);
  }

  20% {
    -webkit-transform: translate(0, 3px);
    -ms-transform: translate(0, 3px);
    transform: translate(0, 3px);
  }

  22% {
    -webkit-transform: translate(0, 0);
    -ms-transform: translate(0, 0);
    transform: translate(0, 0);
  }
}

.icon--vertical-animated {
  -webkit-animation: vertical 2s ease infinite;
  animation: vertical 2s ease infinite;
}
