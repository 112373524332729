.ticket-table-container {
  width: 100%;
  text-align: left;
}

.ticket-table {
  margin: 2rem 0;
}

.ticket-table-table {
  display: flex;
  width: 100%;
  align-items: center;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: space-between;
}

.ticket-table-footer {
  margin-top: 4rem;
}

.ticket-table-mobile-header-container {
  display: flex;
  flex: 1 1 auto;
  justify-content: flex-end;
  align-items: center;
  text-align: right;
  color: #666;
  margin-right: 0.75rem;
  flex-wrap: wrap;
}

.ticket-table-mobile-header {
  display: flex;
  font-size: 0.8rem;
  margin: 0.5rem 0;
  color: #666;
}

.ticket-table__specific-requirements {
  display: flex;
  flex: 1 1 auto;
  justify-content: flex-start;
  text-align: left;
  align-items: center;
  color: #666;
  font-size: 0.8rem;
}

.ticket-table__notes {
  color: #666;
  font-size: 0.9rem;
  margin-top: 1rem;
}

.ticket-table__specific-requirements-icon {
  max-width: 3rem;
  max-height: 1rem;
  margin-right: 1rem;
}

.additional-info-link {
  margin-left: 0.25rem;
  color: #333;
}

@media only screen and (max-width: 569px) {
  .ticket-table__specific-requirements {
    width: 100%;
    margin-bottom: 0.5rem;
    flex: 1 0 auto;
  }
  .ticket-table-mobile-header {
    width: 100%;
    flex: 1 0 auto;
  }
}

@media only screen and (max-width: 479px) {
  .ticket-table {
    margin: 1rem 0;
  }
  .ticket-table-mobile-header-container {
    display: flex;
    font-size: 0.8rem;
    margin: 0.5rem 0;
    justify-content: flex-start;
    text-align: left;
  }
}
