.line-item {
  display: flex;
  flex: 1;
  margin: 0.75rem 0;
  justify-content: space-between;
  font-size: 0.9rem;
}

.line-item__price,
.line-item__booking-fee {
  color: #999;
  font-size: 0.8rem;
}
