.loading-indicator {
  background-color: white;
  display: flex;
  flex-direction: column;
  color: #333;
  justify-content: center;
  align-items: center;
  z-index: 100;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}
