.date-time-bar {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.date-time-bar__lozenge {
  background-color: rgba(0, 0, 0, 0.9); /*custom color*/
  flex: 1 0 50%;
  max-width: 48%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  padding: 0.75rem 0;
  border-radius: 0.1rem;
  border: 0;
  text-align: left;
  font-family: inherit;
  font-size: 1rem;
  outline: 0;
  cursor: pointer;
}

.date-time-bar__lozenge:hover {
  box-shadow: 0 0 0 0.1px rgba(50, 50, 93, 0.01),
    0 2px 5px rgba(50, 50, 93, 0.1), 0 1px 2px rgba(50, 50, 93, 0.05);
  background-color: rgba(0, 0, 0, 1); /*custom color*/
}

.date-time-bar__lozenge--wolf:hover {
  box-shadow: 0 0 0 0.1px rgba(50, 50, 93, 0.01),
    0 2px 5px rgba(50, 50, 93, 0.1), 0 1px 2px rgba(50, 50, 93, 0.05);
  background-color: rgba(0, 255, 59, 0.5); /*custom color*/
}

.date-time-bar__lozenge:focus {
  outline: 0;
}

.date-time-bar__lozenge-selected {
  flex: 3;
  margin: 0 0 0 3%;
}

.date-time-bar__date {
}

.date-time-bar__lozenge-action {
  flex: 1;
  margin: 0 2.5% 0 0;
  padding: 0.75rem 0.5rem;
  cursor: pointer;
  font-size: 0.9rem;
  font-family: "axiformasemibold";
  text-decoration: none;
  white-space: nowrap;
  word-break: keep-all;
  position: relative;
  display: inline-block;
  box-sizing: border-box;
  /*font-family: 'Roboto', sans-serif;*/
  text-transform: uppercase;
  outline: 0;
  letter-spacing: 0.2rem;
  color: #ccc; /*custom color dependant*/
  border: 0.1rem solid rgba(255, 255, 255, 0.6); /*custom color*/
  text-align: center;
  opacity: 0.8;
}

.date-time-bar__lozenge-action--wolf {
  border: 0.1rem solid rgba(0, 255, 59, 0.6); /*custom color*/
}

.date-time-bar__lozenge:hover > .date-time-bar__lozenge-action {
  opacity: 1;
}

.date-time-bar__date {
  font-family: "axiformasemibold";
}

@media only screen and (min-width: 800px) and (max-width: 1159px) {
  .date-time-bar__lozenge-selected {
    flex: 1 0 90%;
    width: 90%;
    margin: 0 5% 1rem 5%;
    text-align: center;
  }
  .date-time-bar__lozenge-action {
    flex: 1 0 90%;
    width: 90%;
    margin: 0 5%;
    text-align: center;
  }
}

@media only screen and (min-width: 525px) and (max-width: 799px) {
  .date-time-bar__lozenge {
    flex: 1 0 100%;
    max-width: 100%;
    margin-bottom: 1rem;
  }
}

@media only screen and (max-width: 524px) {
  .date-time-bar__lozenge {
    flex: 1 0 100%;
    max-width: 100%;
    margin-bottom: 1rem;
  }
  .date-time-bar__lozenge-selected {
    flex: 1 0 90%;
    width: 90%;
    margin: 0 5% 1rem 5%;
    text-align: center;
  }
  .date-time-bar__lozenge-action {
    flex: 1 0 90%;
    width: 90%;
    margin: 0 5%;
    text-align: center;
  }
}
