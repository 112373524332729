/*styles for gatsby splash image*/

.top-banner {
  color: #000;
  background-color: #ff9e1b;
  padding: 0.5rem 0;
  text-align: center;
  width: 100%;
  text-transform: uppercase;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: relative;
}

.top-banner__message {
  flex: 1;
  font-size: 1rem;
}

.top-banner__languages {
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  position: absolute;
  right: 16px;
  top: 6px;
}

.top-banner__language-option {
  display: flex;
  flex-direction: row;
  margin-left: 1rem;
  align-items: center;
  cursor: pointer;
}

.top-banner__language-option-image {
  width: 1.25rem;
  margin: 0;
  padding: 0;
}

.top-banner__language-option-image-image {
  width: 100%;
  margin: 0;
  padding: 0;
}

.top-banner__language-option-label {
  font-size: 0.8rem;
  line-height: 1;
  margin-left: 0.5rem;
  text-transform: none;
}

.credit-image-wrapper {
  display: block;
  width: 280px;
  margin: 1rem auto;
}

.credit-image {
  width: 100%;
}

.merch-images {
  display: flex;
  flex-direction: row;
  flex-wrap: none;
  justify-content: space-between;
  margin: 3rem 0 0 0;
  align-items: center;
}

.merch-image {
  width: 30%;
}

.dw-content-divider {
  border-bottom: 1px solid white;
  margin: 3rem 0;
}

.reviews-container {
  margin: 3rem 0 5rem 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.reviews-container:last-child {
  margin: 6rem 0 5rem 0;
}

.review-main {
  width: 100%;
}

.review-inline {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}

.review-inline--narrow {
  width: 70%;
}

.review {
  font-size: 1.1rem;
  margin: 0 1rem;
}

.review-stars,
.review-publication {
  color: #ff9e1b;
}

.review-quote {
  margin: 0.75rem 0 0 0;
  font-size: 1.5rem;
  line-height: 1.5;
  font-family: axiformasemibold;
}

.review-stars {
  font-size: 2rem;
}

.review-publication {
  margin-top: 0.5rem;
}

.aug-update-container {
  background: #ff9e1b;
}

.aug-update-copy {
  color: #111;
}

.faq-textleft {
  text-align: left;
}

.see-it-safely-banner {
  width: 600px;
  margin: 0 auto;
  display: block;
}

.see-it-safely-banner-image {
  width: 100%;
}

@media only screen and (max-width: 630px) {
  .merch-images {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    margin: 2rem 0 1rem 0;
  }

  .merch-image {
    width: 90%;
    margin-bottom: 1rem;
  }
}

@media only screen and (max-width: 767px) {
  .top-banner__languages {
    position: relative;
    padding-right: 0.5rem;
    right: 0;
    top: 0;
    max-width: 45%;
  }
  .top-banner__message {
    font-size: 0.8rem;
    text-align: center;
    margin-left: 0.5rem;
  }
  .top-banner {
    justify-content: space-between;
  }
  .top-banner__language-option {
    margin-left: 0.5rem;
  }
  .reviews-container {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .reviews-container:last-child {
    margin: 4rem 0 0rem 0;
  }
  .review-inline--narrow {
    width: 100%;
  }
  .review-inline-mobile-break {
    flex-direction: column;
  }
  .review {
    font-size: 1.1rem;
    margin: 1rem 0;
  }
  .review-quote {
    font-size: 1.3rem;
    line-height: 1.3;
  }
  .see-it-safely-banner {
    width: 90%;
    margin: 0 auto;
    display: block;
  }
}

.full-image-background-cover__content-image {
  width: 100%;
}

.full-image-background-cover__content-image-taj {
  width: 15%;
}

.taj-logo {
  display: block;
  margin: 0;
  width: 15%;
}

.large-cta {
  margin: 1rem 0;
}

.double-cta {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex: 1 0 auto;
}

.double-cta-cta {
  margin-right: 2rem;
}

.double-cta-cta:last-chlild {
  margin-right: 0;
}

.react-snow-effect-canvas {
  left: 0;
  height: 42rem;
  position: absolute !important;
}

.gatsby_venue_note {
  color: red;
  font-size: 0.9rem;
}

@media only screen and (min-width: 768px) and (max-width: 1154px) {
  .react-snow-effect-canvas {
    height: 35rem;
  }
}

@media only screen and (min-width: 480px) and (max-width: 767px) {
  .react-snow-effect-canvas {
    height: 28rem;
  }
}

@media only screen and (max-width: 630px) {
  .double-cta {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex: 1 0 auto;
  }
  .double-cta-cta {
    margin-right: 0;
    width: 100%;
  }
  .large-cta {
    margin: 0.5rem 0 0.5rem 0;
  }
}

@media only screen and (max-width: 479px) {
  .react-snow-effect-canvas {
    height: 23rem;
  }
  .large-cta {
    margin: 0.5rem 0 0.5rem 0;
  }
  .full-image-background-cover__content-image-taj {
    width: 25%;
  }
}

/*end styles for gatsby splash image*/
