.page-content-block {
  margin: 0 0 4rem 0;
  width: 100%;
}

.page-content-block--no-margin {
  margin: 0;
}

.page-content-block--top-margin {
  margin-top: 3rem;
}

.page-content-block--multiple {
  display: flex;
  justify-content: center;
  align-items: center;
}
