/* DayPicker styles */

.DayPicker {
  display: inline-block;
  width: 100%;
  outline: 0;
  overflow: hidden;
}

.DayPicker-wrapper {
  position: relative;
  user-select: none;
  padding-bottom: 1rem;
  flex-direction: row;
}

.DayPicker-wrapper:focus {
  outline: 0;
}

.DayPicker-Months {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.DayPicker-Month {
  display: table;
  user-select: none;
  margin-top: 0;
  flex: 1;
  border-spacing: 0.15rem;
  table-layout: fixed;
  width: 100%;
}

.DayPicker-NavBar {
  height: 2rem;
}

.DayPicker-NavButton {
  position: absolute;
  cursor: pointer;
  color: #333;
  height: 1.25rem;
  width: 1.25rem;
  display: inline-block;
  background-size: 50%;
  background-repeat: no-repeat;
  background-position: center;
  opacity: 0.7;
  z-index: 50;
}

.DayPicker-NavButton:hover {
  opacity: 1;
}

.DayPicker-NavButton:focus {
  outline: 0;
}

.DayPicker-NavButton--prev {
  left: 1rem;
  background-image: url(../../../images/cal-left.svg);
  background-size: 100%;
}

.DayPicker-NavButton--next {
  right: 1rem;
  background-image: url(../../../images/cal-right.svg);
  background-size: 100%;
}

.DayPicker-NavButton--interactionDisabled {
  display: none;
}

.DayPicker-Caption {
  padding: 0;
  display: table-caption;
  text-align: center;
  margin: 0 auto;
  caption-side: top;
  column-span: all;
  color: #0a0a0a;
}

.DayPicker-Caption > div {
  font-size: 1.3rem;
  font-family: 'axiformasemibold';
  position: absolute;
  top: 0;
  margin-left: 0;
  margin-right: 0;
  left: 0;
  right: 0;
}

.DayPicker-Weekdays {
  margin-top: 1rem;
  display: table-header-group;
}

.DayPicker-WeekdaysRow {
  display: table-row;
}

.DayPicker-Weekday {
  display: table-cell;
  padding: 0.5rem 0;
  font-size: 0.8rem;
  text-transform: uppercase;
  text-align: center;
  color: #666;
  max-width: 0;
}

.weekday-title {
  width: 100%;
}

.weekday-title--mobile {
  display: none;
}

.DayPicker-Weekday abbr[title] {
  border-bottom: none;
  text-decoration: none;
}

.DayPicker-Body {
  display: table-row-group;
}

.DayPicker-Week {
  display: table-row;
}

.DayPicker-Day {
  display: table-cell;
  padding: 0 1%;
  text-align: left;
  cursor: pointer;
  vertical-align: top;
  font-size: 0.9rem;
  width: 10%;
  min-width: 10%;
  height: 6.5rem;
  border: 1px solid #ddd;
  color: #1f1f1f;
  background: #fefefe;
  margin: 0.3rem;
  position: relative;
  box-sizing: border-box;
  overflow: hidden;
}

.DayPicker-Day:focus {
  outline: 0;
}

.DayPicker-WeekNumber {
  display: table-cell;
  padding: 0.5rem;
  text-align: right;
  vertical-align: middle;
  min-width: 1rem;
  font-size: 0.8em;
  cursor: pointer;
  color: #8b9898;
  border-right: 1px solid #eaecec;
}

.DayPicker--interactionDisabled .DayPicker-Day {
  cursor: default;
}

.DayPicker-Footer {
  padding-top: 0.5rem;
}

.DayPicker-TodayButton {
  border: none;
  background-image: none;
  background-color: transparent;
  box-shadow: none;
  cursor: pointer;
  color: #4a90e2;
  font-size: 0.875em;
}

/* Default modifiers */

.DayPicker-Day--today {
  border: 0.1rem solid rgba(0, 0, 0, 0.5); /*custom colour*/
}

.DayPicker-Day--today .day-picker-day__date-number {
  font-family: 'axiformasemibold';
}

.DayPicker-Day--outside {
  cursor: default;
  color: #8b9898;
}

.DayPicker-Day--disabled {
  color: #ccc;
  cursor: default;
  background-color: #e1e1e1;
}

.DayPicker-Day--disabled:hover {
  box-shadow: none;
  background-color: #e1e1e1;
  color: #ccc;
}

/* Example modifiers */

.DayPicker-Day--sunday {
  background-color: #f7f8f8;
}

.DayPicker-Day--sunday:not(.DayPicker-Day--today) {
  color: #dce0e0;
}

.DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside) {
  position: relative;
  color: #f0f8ff;
  background-color: #4a90e2;
}

.DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside):hover {
  background-color: #51a0fa;
}

.DayPicker:not(.DayPicker--interactionDisabled)
  .DayPicker-Day:not(.DayPicker-Day--disabled):not(.DayPicker-Day--selected):not(.DayPicker-Day--outside):hover {
  background-color: #f1f1f1;
  /*box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);*/
}

/* DayPickerInput */

.day-picker-day__date-number {
  display: inline-block;
  vertical-align: top;
  width: 20%;
  font-size: 0.9rem;
  margin-top: 0.3rem;
  margin-right: 0.3rem;
}

.DayPickerInput {
  display: inline-block;
}

.DayPickerInput-OverlayWrapper {
  position: relative;
}

.DayPickerInput-Overlay {
  left: 0;
  z-index: 1;
  position: absolute;
  background: white;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);
}

.day-picker-day__performances {
  width: 70%;
  position: absolute;
  right: 0.3rem;
  top: 0;
}
.day-picker-day__performances--mobile {
  display: block;
}

.mobile-availability {
  position: relative;
  box-sizing: border-box;
  margin: 0;
  border-radius: 2px;
  border: 1px solid #ff9e1b; /*custom color*/
  color: #000; /*custom color*/
  width: 100%;
}

.mobile-availability__indicator {
  height: 3.5rem;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.mobile-availability__label {
  font-size: 0.75rem;
  font-weight: 400;
  display: flex;
  flex-direction: column;
  text-align: center;
  z-index: 50;
}

.mobile-availability__label-performance {
  flex: 1 0 auto;
  margin-bottom: 0.1rem;
}

.performance-info__price {
  flex: 1 0 auto;
  color: #666666;
  font-size: 0.8rem;
  font-family: 'axiformasemibold';
}

.mobile-availability__indicator-bar {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  background-color: rgba(255, 158, 27, 0.3);
}
.mobile-availability__indicator-bar--sold-out {
  width: 100%;
  background-color: rgba(245, 149, 149, 0.3);
}

.day__specific-requirements {
  position: absolute;
  bottom: 0rem;
  right: 0.5rem;
  width: 2rem;
  min-width: 2rem;
  max-width: 2rem;
  z-index: 100;
}

.day__specific-requirements-icon {
  width: 100%;
}

@media only screen and (min-width: 880px) and (max-width: 999px) {
  .day-picker-day__performances {
    width: 70%;
  }
}

@media only screen and (max-width: 879px) {
  .day-picker-day__performances {
    width: 90%;
    top: 0.9rem;
    right: 5%;
  }
  .day-picker-day__date-number {
    font-size: 0.6rem;
  }
  .weekday-title {
    display: none;
  }

  .weekday-title--mobile {
    display: block;
  }
  .day-picker-day__specific-requirements {
    width: 2.5rem;
    min-width: 2.5rem;
    max-width: 2.5rem;
  }
  .DayPicker-Caption > div {
    font-size: 1rem;
  }
  .mobile-availability__indicator {
    height: 3rem;
  }
  .performance-info__price {
    font-size: 0.65rem;
    font-family: 'axiformasemibold';
  }
  .mobile-availability__label {
    font-size: 0.6rem;
    font-family: 'axiformasemibold';
    display: flex;
    flex-direction: column;
    text-align: center;
    z-index: 50;
  }
}

@media only screen and (max-width: 559px) {
  .day__specific-requirements {
    display: none;
  }
  .mobile-availability__indicator {
    height: 2.2rem;
  }
  .DayPicker-Day {
    height: 4rem;
  }
  .DayPicker-Month {
    margin: 0;
  }
  .day-picker-day__performances {
    display: none;
  }
  .day-picker-day__performances--mobile {
    display: block;
    position: absolute;
    right: 0.2rem;
    left: 0.2rem;
    margin-top: 0.2rem;
  }
  .DayPicker-Caption > div {
    font-size: 1rem;
  }
  .performance-info__price {
    font-size: 0.6rem;
  }
  .mobile-availability__label {
    font-size: 0.55rem;
    font-family: 'axiformasemibold';
  }
}
