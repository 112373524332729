.footer-bar {
  background-color: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 999;
  min-height: 5.5rem;
  height: 5.5rem;
  padding: 0 5%;
  box-shadow: -1px -1px 3px rgba(50, 50, 93, 0.11),
    0 1px 3px rgba(0, 0, 0, 0.08);
}
