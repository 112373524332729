.video-player {
  position: relative;
  padding-bottom: 56.25%; /* 16:9 */
  padding-top: 25px;
  height: 0;
  margin: 5rem 0;
  background: black;
}

.video-player--gatsby {
  margin: 0;
}

.video-player__iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
