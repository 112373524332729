/*start slider*/

.slider-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
  position: relative;
  min-height: 42rem;
}

@media only screen and (max-width: 479px) {
  .slider-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: stretch;
    position: relative;
    min-height: 20rem;
  }
}

/*end slider*/
