.full-image-background-cover {
  position: absolute;
  z-index: 5;
  width: 100%;
  max-width: 100%;
  bottom: 0;
  height: 100%;
  color: #fff;
  font-size: 2.5rem;
  padding-top: 7.5rem;
  /* background: -moz-linear-gradient(
    top,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.95) 100%
  ); FF3.6-15 */
  /* background: -webkit-linear-gradient(
    top,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.95) 100%
  ); Chrome10-25,Safari5.1-6 */
  /* background: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.95) 100%
  ); W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  /* filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#a6000000',GradientType=0 ); IE6-9 */
}

@media only screen and (max-width: 479px) {
  .full-image-background-cover {
    padding-top: 6rem;
  }
}
