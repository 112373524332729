.content-container {
  width: 90%;
  background: #fff;
  display: block;
  margin: 0 auto 0 auto;
  padding: 0 0 2rem 0;
}

.content-container--gatsby {
  background: none;
  padding: 2rem 4%;
  width: 82%;
}

.content-container--gatsby-booking {
  margin-bottom: 4rem;
  background-color: #ffffff;
  padding: 2rem 4%;
  width: 82%;
}

@media only screen and (max-width: 799px) {
  .content-container {
    width: 94%;
  }
  .content-container--gatsby {
    padding: 1rem 2%;
    width: 90%;
  }
  .content-container--gatsby-booking {
    box-sizing: border-box;
    width: 100%;
    margin-left: 0;
    margin-right: 0;
  }
}
