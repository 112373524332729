.form-container {
  width: 100%;
  margin: 0;
  overflow: hidden;
  padding: 0;
  text-align: left;
}

.form {
  width: 100%;
  font-family: 'Roboto', sans-serif;
}

.form__header {
}

.form__header-title,
.form__section-header-title {
  font-size: 1.1rem;
  margin: 0;
  padding: 0;
}

.form__header-notes {
  font-size: 0.9rem;
  margin-top: 1rem;
  padding: 0;
  line-height: 1.2;
}
.form__form-error {
  color: #ff3323;
  font-size: 0.9rem;
  margin-bottom: 0.5rem;
  padding: 0;
  display: block;
}

.form__section-header {
  margin: 2rem 0 0 0;
  border-top: 1px solid;
  border-color: #ddd;
  padding: 1rem 1rem 0.5rem 0;
}

.form__form-area {
  padding: 0;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
}

.form__footer {
  background-color: transparent;
  padding: 0;
  clear: both;
}

.form__submit {
  text-align: right;
  flex: 1;
}
