.full-image-background-cover__content {
}

.full-image-background-cover__content-dw-logo {
  width: 100%;
  flex: 3 1 auto;
  position: relative;
  max-height: 31rem;
}

@media only screen and (min-width: 1600px) {
  .full-image-background-cover__content-dw-logo {
    width: 90%;
    flex: 1;
    position: relative;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1154px) {
  .full-image-background-container--dw-splash {
    background-position: 43% 0%;
  }
  .full-image-background-cover__content-dw-logo {
    width: 100%;
    flex: 1;
    position: relative;
  }
}

@media only screen and (min-width: 480px) and (max-width: 767px) {
  .full-image-background-container--dw-splash {
    background-position: 48% 0%;
  }
  .full-image-background-cover__content-dw-logo {
    width: 100%;
    flex: 1;
    position: relative;
  }
}

@media only screen and (max-width: 479px) {
  .full-image-background-cover {
    padding-top: 6rem;
  }

  .full-image-background-cover__content-dw-logo {
    width: 100%;
    flex: 1;
    position: relative;
  }
}

/*end styles for gatsby splash image*/
