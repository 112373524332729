.main-content {
  margin: 0;
  padding: 0 0 3rem 0;
  width: 100%;
}

.main-content--event {
  padding: 0 0 7rem 0;
}

.main-content--regular {
  background-color: #05283a;
}

.main-content--gatsby {
  background-color: #0a0a0a; /*custom colour*/
  padding: 3rem 0;
}

.main-content--notoppadding {
  padding: 0 0 3rem 0;
}

.main-content--wolf {
  margin-top: 3rem;
}
