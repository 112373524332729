.payment-form__note {
  color: #a87f06; /*custom color darker*/
  font-size: 0.7rem;
  margin: -0.75rem 0 0.75rem 0;
  display: flex;
  align-items: flex-start;
}

.payment-form__icon {
  margin-right: 0.3rem;
}

.payment-request-button {
  width: 100%;
  margin-bottom: 1.2rem;
}
