.confirm-order__content {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  text-align: left;
}

.confirm-order__content-section {
  flex: 0 1 48%;
}

.confirm-order__specific-requirements {
  flex: 1 0 100%;
  margin-bottom: 1.5rem;
  font-size: 1.5rem;
  font-family: 'axiformasemibold';
  text-align: center;
  color: #ff3323;
}

.forgot-password-text {
  color: #111;
}

@media only screen and (max-width: 1139px) {
  .confirm-order__content-section {
    flex: 0 100%;
    margin-bottom: 0;
  }
  .confirm-order__content-section--payment {
    order: 1;
  }
  .confirm-order__content-section--order {
    order: 2;
    margin-top: 2rem;
  }
}
