.DWTF-footer {
  margin: 3rem 0 3rem 0;
  padding: 0;
  color: white;
  width: 90%;
  padding: 0 5%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
}

.DWTF-footer-span {
  flex: 1;
}

.DWTF-footer-span--links {
  flex: 2 1 auto;
}

.DWTF-footer-span--copyright {
  text-align: left;
  line-height: 1.5;
  font-size: 0.9rem;
}

.DWTF-footer-span--copyright:last-child {
  text-align: right;
}

.DWTF-footer-span--social {
}

.DWTF-footer-span--logo {
}

.DWTF-footer-copyright {
  color: rgba(255, 255, 255, 0.8);
  font-size: 0.65rem;
}

.DWTF-footer-copyright-link:hover {
  color: #fff;
}

.DWTF-footer__logo {
  width: 3rem;
  float: right;
}

.DWTF-footer__logo-image {
  width: 100%;
  display: block;
}

.DWTF-footer-menu__link {
  margin-left: 1.5rem;
  font-family: 'axiformasemibold';
  cursor: pointer;
  color: #ff9e1b;
}

.DWTF-footer-menu__link:first-child {
  margin-left: 0;
}

.social {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}

.social__icon {
  flex: 1 1 auto;
  width: 2rem;
  max-width: 2rem;
  margin-right: 2rem;
}

.social__icon:last-child {
  margin-right: 0;
}

.social__icon-icon {
  width: 100%;
}

@media only screen and (max-width: 845px) {
  .DWTF-footer {
    margin: 1rem 0 2rem 0;
    flex-direction: column;
  }
  .DWTF-footer-span {
    margin-bottom: 1.5rem;
  }
  .DWTF-footer-span--copyright {
    text-align: center;
    flex: 2;
    order: 3;
  }
  .DWTF-footer-span--social {
    flex: 2;
    order: 2;
  }
  .DWTF-footer-span--links {
    flex: 1;
    order: 1;
    font-size: 0.9rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .DWTF-footer-menu__link:first-child {
  }
  .DWTF-footer-menu__link {
    margin: 0.75rem 0;
  }
}
