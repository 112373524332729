.discount {
  width: 100%;
}

.discount__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.5rem;
}

.discount__text {
  font-size: 0.8rem;
}

.discount__button {
  font-size: 0.8rem;
  color: #ff9e1b;
  border: 0;
  letter-spacing: 0;
  box-shadow: none;
  padding: 0;
  text-transform: none;
  background-color: transparent;
  font-family: "axiformasemibold";
}

.discount__button:focus {
  outline: 0;
}
