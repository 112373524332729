/**
* ----------------------------------------------
* Demo styles
* ----------------------------------------------
**/

.hc_logo_wrapper {
  width: 400px;
  display: block;
  margin: 1rem auto;
}

.hc_logo {
  width: 100%;
}

.u-position-absolute {
  position: absolute;
}

.u-position-relative {
  position: relative;
}

.cast-accordion {
  width: 94%;
  margin: 0 3%;
  text-align: center;
}

.cast-accordion__item {
  /* border-bottom: 1px solid rgba(255, 255, 255, 0.3); */
  outline: 0;
}

.cast-accordion__item--covid {
  border-bottom: none;
}

.cast-accordion__button {
  outline: 0;
}

.cast-accordion__item:focus {
  outline: 0;
}

/*.cast-accordion__item:last-child {
  border-bottom: none;
}*/

.cast-accordion__item--has-icon {
  position: relative;
}

.cast-accordion__heading {
  cursor: pointer;
  padding: 0 5% 1rem 5%;
  width: 90%;
  border: none;
  position: relative;
}

.cast-accordion__heading:focus {
  outline: 0;
}

.cast-accordion__panel {
  padding: 0 1rem 1rem 1rem;
  display: block;
  animation: fadein 0.35s ease-in;
}

.cast-accordion__panel--hidden {
  display: none;
  opacity: 0;
  animation: fadein 0.35s ease-in;
}

.cast-accordion__heading > *:last-child,
.cast-accordion__panel > *:last-child {
  margin-bottom: 0;
}

.cast-accordion__arrow {
  display: inline-block;
  position: relative;
  width: 24px;
  height: 12px;
  position: absolute;
  bottom: 20px;
  right: 0;
  margin-top: -6px;
}

.cast-accordion__arrow--covid {
  display: none;
}

.cast-accordion__arrow::after,
.cast-accordion__arrow::before {
  display: block;
  position: absolute;
  right: 0;
  top: 50%;
  width: 10px;
  height: 2px;
  background-color: #333;
  content: '';
}

.cast-accordion__arrow::after,
.cast-accordion__arrow::before {
  background-color: #fff;
}

.cast-accordion__arrow::before {
  left: 4px;
  transform: rotate(45deg);
}

[aria-expanded='true'] .cast-accordion__arrow::before,
[aria-selected='true'] .cast-accordion__arrow::before {
  transform: rotate(-45deg);
}

.cast-accordion__arrow::after {
  right: 4px;
  transform: rotate(-45deg);
}

[aria-expanded='true'] .cast-accordion__arrow::after,
[aria-selected='true'] .cast-accordion__arrow::after {
  transform: rotate(45deg);
}

.cast-accordion__arrow::before,
.cast-accordion__arrow::after {
  transition: transform 0.25s ease, -webkit-transform 0.25s ease;
}

.covid-cast-accordion-content {
  color: white;
  font-family: 'Roboto', sans-serif;
  font-size: 18px;
  text-align: left;
}

.covid-cast-accordion-additional-info {
  margin-top: 2rem;
  background: rgba(0, 0, 0, 0.2);
  padding: 0.5rem 1.5rem 1.5rem 1.5rem;
}

.covid-action-item {
  margin-bottom: 1.5rem;
}

.covid-video {
  width: 100%;
}

@media only screen and (min-width: 480px) and (max-width: 700px) {
  .hc_logo_wrapper {
    width: 300px;
  }
}

@media only screen and (max-width: 479px) {
  .cast-accordion {
    width: 100%;
    margin: 0;
    text-align: center;
  }
  .cast-accordion__arrow {
    /* display: none; */
  }
  .hc_logo_wrapper {
    width: 200px;
  }
}

/* -------------------------------------------------- */
/* ---------------- Animation part ------------------ */
/* -------------------------------------------------- */

@keyframes fadein {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes move-down {
  0% {
    transform: translateY(0);
  }
  10% {
    transform: translateY(0);
  }
  20% {
    transform: translateY(5px);
  }
  30% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(0);
  }
}

@keyframes move-up {
  0% {
    transform: translateY(0);
  }
  10% {
    transform: translateY(0);
  }
  20% {
    transform: translateY(-5px);
  }
  30% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(0);
  }
}

.cast-accordion__heading--animated:hover .cast-accordion__arrow {
  animation-name: move-down;
  animation-duration: 1.5s;
}

.cast-accordion__heading--animated[aria-expanded='true']:hover
  .cast-accordion__arrow {
  animation-name: move-up;
  animation-duration: 1.5s;
}

.faq_link {
  text-decoration: underline;
  cursor: pointer;
}
