.booking-container {
  min-height: 30rem;
  padding: 1.5rem 0;
  position: relative;
}

.booking-container__header {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 0 1.5rem 0;
}
.booking-container__left {
  flex: 1 2;
}
.booking-container__title {
  font-size: 1.3rem;
  line-height: 2rem;
  margin-top: 1rem;
  border-bottom: 0.1rem solid #071252; /*custom color*/
  letter-spacing: 0.3rem;
  font-family: 'axiformasemibold';
  text-transform: uppercase;
  padding: 0 1rem 1.2rem 1.2rem;
  display: inline-block;
  flex: 2 1 auto;
  color: #0a0a0a;
}
.booking-container__body {
  color: #0a0a0a;
}
.booking-container__title--gatsby {
  font-size: 2.5rem;
  letter-spacing: 0;
  border-bottom: none;
  color: #0a0a0a;
}
.booking-container__countdown {
  flex: 1 2;
  text-align: right;
}
.booking-fade-enter {
  opacity: 0.01;
}

.booking-fade-enter.booking-fade-enter-active {
  opacity: 0.01;
  transition: opacity 500ms ease-in;
}

.booking-fade-leave {
  opacity: 1;
}

.booking-fade-leave.ebooking-fade-leave-active {
  opacity: 0.01;
  transition: opacity 300ms ease-in;
}

.booking-fade-appear {
  opacity: 0.01;
}

.booking-fade-appear.booking-fade-appear-active {
  opacity: 1;
  transition: opacity 0.5s ease-in;
}

@media screen and (max-width: 479px) {
  .booking-container {
    min-height: 10rem;
    padding: 1.5rem 0rem;
  }
  .booking-container__title {
    font-size: 1.5rem;
  }
  .booking-container__title--wolf {
    font-size: 2rem;
  }
  .booking-container__countdown,
  .booking-container__left {
    display: none;
  }
  .booking-container__header {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 0 1rem 0;
  }
  .booking-container--wolf .DayPicker-Caption > div {
    font-size: 1.3rem;
  }
}
