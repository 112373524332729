.App {
  text-align: center;
  min-height: 100vh;
  height: 100%;
  background: #0a0a0a;
}

.App--DWTF {
  background: #0a0a0a;
}

.App--gatsby-terms {
  padding-bottom: 3rem;
}

.ticketing-widget-container {
  margin: 3rem 0;
  padding: 0 5%;
}

.dw-main-artwork-desktop {
  width: 100%;
}

.dw-main-artwork-mobile {
  display: none;
}

.dw-main-artwork-image {
  width: 100%;
}

.intro-logo {
  width: 70%;
  margin: 2rem auto;
  display: block;
}

body {
  margin: 0;
  padding: 0;
}

.lottery_form {
  margin: 2rem 0;
}

@media only screen and (max-width: 630px) {
  .dw-main-artwork-desktop {
    display: none;
  }
  .dw-main-artwork-mobile {
    display: block;
    width: 100%;
  }
}

@media only screen and (max-width: 479px) {
  .mobile-hide {
    display: none;
  }
  .ticketing-widget-container {
    margin: 1rem 0.55rem;
    padding: 0;
  }
}

.link {
  cursor: pointer;
}

.body-text-link {
  color: #a95a13;
  text-decoration: underline;
}

.body-text-link--black {
  color: #000;
}

.gatsby-body-text-link {
  text-decoration: underline;
  color: #000;
}

.cookie-banner-link {
  text-decoration: underline;
}

/*content Styles*/

.margin-top {
  margin-top: 2rem;
}

.terms-cta {
  margin: 2rem 0;
}

/*end content Styles*/

.booking-wrapper {
  margin: 2rem 0;
  padding: 0 8%;
}

.booking-section-header {
  text-align: left;
  margin-top: 0;
}

.booking-section-header--inline {
  flex: 1;
}

.booking-nav {
  margin-bottom: 0;
  text-align: left;
}

.booking-nav-link {
  font-size: 0.8rem;
  color: #666;
}

.booking-nav-link__icon {
  margin-right: 0.5rem;
}

.booking--event {
  padding: 0 4%;
}

@media only screen and (max-width: 1064px) {
  .booking-wrapper {
    padding: 0 4%;
  }
}

@media only screen and (max-width: 799px) {
  .booking-wrapper {
    padding: 0 2%;
  }
}

@media only screen and (max-width: 509px) {
  .booking-section-header--inline {
    font-size: 1.2rem;
    flex: 1 0 100%;
    margin-bottom: 0.5rem;
  }
}

/*cards*/

.content-card-container {
  margin-top: 2rem;
  width: 94%;
  padding: 0 3%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
}
/* @media only screen and (max-width: 479px) {
  .content-card-container {
    margin-top: 0;
    justify-content: flex-start;
    overflow: scroll;
    flex-wrap: nowrap;
    border-right: 1px solid #ccc;
  }
  .content-card-container--text-only {
    
  }
} */

/*end cards*/

/*map*/

.map--gatsby {
  min-height: 4rem;
  background: #333;
  height: auto;
  position: relative;
}

/*end map*/

/*end mobile styles*/

/*404 error page styles*/

.error-page-cta {
  margin-top: 2rem;
}

/*404 error page styles*/

/*transition styles*/
.fade-enter {
  opacity: 0;
  z-index: 1;
}

.fade-enter.fade-enter-active {
  opacity: 1;
  transition: opacity 0.3s ease-in;
}

.fade-leave {
  opacity: 1;
  z-index: 1;
}

.fade-leave.fade-leave-active {
  opacity: 0;
  transition: opacity 0.3s ease-in;
}

.fade-appear {
  opacity: 0;
  z-index: 2;
}

.fade-appear.example-appear-active {
  opacity: 1;
  transition: opacity 10ms ease-in;
}

.terms-logo {
  width: 40%;
  margin: 2rem auto;
  display: block;
}

@media only screen and (max-width: 479px) {
  .terms-logo {
    width: 60%;
  }
  .terms-cta {
    margin-top: 3rem;
  }
}

.specific-requirements-section {
  margin: 3rem 0 2rem 0;
  border-top: 1px solid #999;
  padding-top: 2rem;
}

.specific-requirements-section a {
  text-decoration: underline;
}

.specific-requirements-image {
  width: 100%;
  margin: 2rem 0;
}

.cast_small {
  font-size: 0.7rem;
}
