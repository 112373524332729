.button {
  padding: 0.75rem 1rem;
  cursor: pointer;
  font-size: 1rem;
  text-decoration: none;
  white-space: nowrap;
  word-break: keep-all;
  position: relative;
  display: inline-block;
  box-sizing: border-box;
  font-family: 'axiformasemibold', sans-serif;
  text-transform: uppercase;
  box-shadow: 0 0 0 0.1px rgba(50, 50, 93, 0.01),
    0 2px 5px rgba(50, 50, 93, 0.1), 0 1px 2px rgba(50, 50, 93, 0.05);
  outline: 0;
  letter-spacing: 0.3rem;
  transition: background-color 200ms ease-in-out;
  border: none;
  background-color: #ff9e1b;
  color: #0a0a0a;
}

.button:disabled {
  background: #999;
  color: #666;
}

.button--second {
  margin-left: 0.5rem;
}

.button--primary {
  background-color: rgba(169, 90, 19, 0.7);
  color: #f1f1f1;
  border: 0.1rem solid #fff;
}

.button--primary:visited {
  color: #f1f1f1;
}

.button--primary:hover {
  background-color: rgba(169, 90, 19, 1);
  color: #fff;
}

.button--secondary {
  background-color: #ff9e1b;
  color: #666;
  border: 0.1rem solid #ddd;
}

.button--secondary:hover {
  color: #333;
  box-shadow: 0 0 0 0.5px rgba(50, 50, 93, 0.02),
    0 2px 5px rgba(50, 50, 93, 0.2), 0 1px 2px rgba(50, 50, 93, 0.06);
  background-color: #f6f6f6;
}

.button--gatsby {
  background-color: #ff9e1b;
  font-family: 'axiformasemibold', sans-serif;
  color: #0a0a0a;
  border: 0;
  padding: 1rem 1rem 0.75rem 1rem;
}

.button--gatsby:hover {
  background-color: #ed9118;
}

.button--short {
  padding: 0.5rem 0.75rem;
  font-size: 0.9rem;
  letter-spacing: 0.15rem;
}

.button--large {
  padding: 1.25rem 1.9rem 1rem 2rem;
  font-size: 1.3rem;
  border-radius: 0.1rem;
}

.button--icon {
}

.button--bottom-bar {
  min-width: 30%;
  background-color: #ff9e1b; /*custom colour*/
  color: #333;
  border: 0.1rem solid #a87f06;
}

.button--full {
  width: 100%;
}

.button--mobile-full {
}

.button--delete {
  background-color: #fff;
  color: #999;
  border: 0.1rem solid #ccc;
  letter-spacing: 0;
  font-size: 0.8rem;
  box-shadow: none;
  padding: 0.5rem;
}

.button--delete:hover {
  background-color: #e0395c;
  color: white;
}

.button-link-style {
  color: #ff9e1b;
  border: 0;
  letter-spacing: 0;
  box-shadow: none;
  padding: 0;
  text-transform: none;
  flex: 1;
  background-color: transparent;
  font-family: 'axiformasemibold';
}

.button-link-style:focus {
  outline: 0;
}

.button--manage-cards {
}

.button--booking {
  background-color: #daa520; /*custom colour*/
  color: #333;
  border: 0;
  letter-spacing: 0;
  font-size: 0.9rem;
  box-shadow: none;
  padding: 0.55rem;
  flex: 1;
}

.button--booking-secondary {
  background-color: #ccc; /*custom colour*/
  color: #999;
  border: 0;
  letter-spacing: 0;
  font-size: 0.8rem;
  box-shadow: none;
  padding: 0.5rem;
  flex: 1;
}

.button--add-new-card {
  flex: 1;
  width: 100%;
  min-width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 1rem 1rem;
  border-radius: 0.1rem;
  border: 1px solid #ccc;
  font-size: 1rem;
  margin-bottom: 1rem;
  letter-spacing: 0;
  text-transform: none;
  box-shadow: none;
  font-weight: 400;
}

.button--social {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 1rem 1rem;
  border-radius: 0.1rem;
  border: none;
  font-size: 1rem;
  margin-bottom: 1rem;
  letter-spacing: 0;
  text-transform: none;
  box-shadow: none;
  font-family: 'axiformasemibold';
  color: #fff;
  cursor: pointer;
  outline: 0;
}

.button--share-facebook {
  background-color: #3b5998;
}

.button--share-twitter {
  background-color: #00aced;
}

.button--share-whatsapp {
  background-color: #51c176;
}

.button--margin-top {
  margin-top: 1rem;
}

.button-spinner {
  display: flex;
  justify-content: center;
  align-items: center;
}
.button-spinner > div {
  width: 1rem;
  height: 1rem;
}
.button--wolf > .button-spinner {
  height: 1.875rem;
}
.button--small {
  font-size: 0.8rem;
  padding: 0.4rem 0.4rem;
}

@media only screen and (max-width: 479px) {
  .button--full {
    padding-left: 0;
    padding-right: 0;
    width: 100%;
  }
  .button--mobile-full {
    width: 100%;
  }
  .button--large {
    padding: 1rem 1rem 0.9rem 1rem;
    font-size: 1rem;
  }
  .button--table {
    padding: 0.2rem 0.4rem;
    font-size: 0.9rem;
  }
}
