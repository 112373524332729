.text-block {
  width: 60%;
  margin: 0 auto;
  display: block;
}

.text-block--text-left {
  text-align: left;
}

.text-block--wide {
  width: 80%;
  margin: 0 auto;
  display: block;
}

.text-block--full {
  width: 100%;
}

.text-block--top-margin {
  margin-top: 2rem;
}

.text-block--top-padding {
  padding-top: 2rem;
}

.small-text {
  font-size: 0.7rem;
}

@media only screen and (max-width: 799px) {
  .text-block {
    width: 90%;
  }
}
