.full-image-background-cover__content-wrap {
  position: absolute;
  flex: 1;
  z-index: 10;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 45%;
  bottom: 25%;
  left: 2%;
  line-height: 2rem;
}

.full-image-background-cover__content-wrap--top-margin {
  top: 40%;
}

.full-image-background-cover__content-wrap--small-top-margin {
  top: 20%;
}

/* make keyframes that tell the start state and the end state of our object */
@-webkit-keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-moz-keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.full-image-background-cover__content-wrap--fade {
  opacity: 0; /* make things invisible upon start */
  -webkit-animation: fadeIn ease-in 1; /* call our keyframe named fadeIn, use animattion ease-in and repeat it only 1 time */
  -moz-animation: fadeIn ease-in 1;
  animation: fadeIn ease-in 1;

  -webkit-animation-fill-mode: forwards; /* this makes sure that after animation is done we remain at the last keyframe value (opacity: 1)*/
  -moz-animation-fill-mode: forwards;
  animation-fill-mode: forwards;

  -webkit-animation-duration: 1s;
  -moz-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-delay: 200ms;
  -moz-animation-delay: 200ms;
  animation-delay: 200ms;
}

@media only screen and (min-width: 768px) and (max-width: 1070px) {
  .full-image-background-cover__content-wrap--top-margin {
    top: 40%;
  }
  .full-image-background-cover__content-wrap {
    left: 5%;
  }
  .full-image-background-cover__content-wrap {
    width: 48%;
  }
}

@media only screen and (min-width: 480px) and (max-width: 767px) {
  .full-image-background-cover__content-wrap--top-margin {
    top: 45%;
  }
  .full-image-background-cover__content-wrap {
    left: 5%;
  }
  .full-image-background-cover__content-wrap {
    width: 50%;
  }
}

@media only screen and (max-width: 479px) {
  .full-image-background-cover__content-wrap--top-margin {
    top: 35%;
  }
  .full-image-background-cover__content-wrap {
    left: 10%;
  }
  .full-image-background-cover__content-wrap--small-top-margin {
    top: 23%;
  }
}
