.slider-item {
  background: #999;
  color: #1f1f1f;
  margin: 0;
  box-sizing: border-box;
  position: relative;
  height: auto;
  width: 100%;
}

.slider-item__image {
  width: 100%;
}
