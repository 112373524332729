.ticket-table-row-group {
  flex: 1 0 auto;
  display: flex;
  width: 96%;
  background: #0a0a0a;
  margin: 0.5rem 0;
  padding: 1rem 2%;
  border-radius: 0.1rem;
  z-index: 0;
}

.ticket-table-row-group:hover {
  cursor: pointer;
  background-color: #000;
  box-shadow: 0 0 0 0.1px rgba(50, 50, 93, 0.01),
    0 2px 5px rgba(50, 50, 93, 0.1), 0 1px 2px rgba(50, 50, 93, 0.05);
}

.ticket-table-row-group:first-child {
  margin: 0 0 0.5rem 0;
}

.ticket-table-row-group:last-child {
  margin: 0.5rem 0 0 0;
}

.ticket-table-row-group--low-left {
  border-bottom: 0.3rem solid rgba(224, 57, 92, 0.5);
}

.ticket-table-row-group--in-basket {
  background-color: #000; /*custom color*/
}

.ticket-table-row-group--sold-out {
  opacity: 0.5;
  border-bottom: 0;
}

.ticket-table-row-group--sold-out:hover {
  opacity: 0.5;
  box-shadow: none;
  cursor: default;
  background-color: #999;
}

.ticket-table-row {
  flex: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.ticket-table-row-item {
  flex: 1;
}

.ticket-table-row-item--ticket-info {
  flex: 3;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
}

.ticket-table-row-item--ticket-price {
  margin: 0 1rem;
  display: flex;
  justify-content: flex-start;
  flex: 2;
  align-items: center;
}

.ticket-table-row-item--ticket-controls {
  position: relative;
}

.ticket-table-row-item--ticket-controls--disabled {
  /* display: none; */
}

.ticket-table-row-item-ticket-name {
  font-size: 1.1rem;
  font-family: "axiformasemibold";
  flex: 1 1 auto;
}

.ticket-table-row-item-ticket-description {
  font-size: 0.8rem;
  color: #ccc;
  flex: 1;
  margin-top: 0.5rem;
}

.ticket-table-row-item--ticket-price-face {
  font-size: 1.1rem;
  margin: 0 0.5rem 0 0;
}

.ticket-table-row-item--ticket-price-fee {
  font-size: 0.7rem;
  color: #ccc;
}

.ticket-table-row-item-ticket-buttons {
  z-index: 10;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0;
}

.ticket-table-row-item-ticket-control {
  display: flex;
  flex: 1;
}

.ticket-table-row-item-ticket-control--plus {
  justify-content: flex-end;
}

.ticket-table-row-item-ticket-control--minus {
  justify-content: flex-start;
}

.ticket-table-row-item-ticket-control-tally {
  font-family: "axiformasemibold";
  font-size: 1.5rem;
  flex: 2;
  text-align: center;
}

.ticket-control-icon {
  font-size: 14px;
}

.ticket-table-row-item-ticket-control-button {
  letter-spacing: 0;
  background-color: #ff9e1b;
  border: 0.1rem solid rgba(0, 0, 0, 0.6); /*custom color*/
  border-radius: 50%;
  box-shadow: none;
  padding: 0;
  width: 2rem;
  height: 2rem;
  color: rgba(0, 0, 0, 0.8); /*custom color*/
  /* display: flex;
  justify-content: center;
  align-items: center; */
}

.ticket-table-row-item-ticket-control-button:hover {
  color: rgba(0, 0, 0, 1); /*custom color*/
  border: 0.1rem solid rgba(0, 0, 0, 0.8); /*custom color*/
}

.ticket-table-row-item-ticket-remaining {
  position: absolute;
  display: block;
  font-size: 0.7rem;
  color: #e0395c;
  bottom: -0.5rem;
  width: 100%;
  text-align: center;
}

.ticket-table-row-item--sold-out {
  justify-content: center;
  display: flex;
}

.sold-out-label {
  text-align: right;
}

@media only screen and (min-width: 480px) and (max-width: 859px) {
  .ticket-table-row-item--ticket-info {
    flex: 2;
  }
  .ticket-table-row-item--ticket-controls {
    flex: 1.3;
  }
  .ticket-table-row-item--ticket-price {
    flex: 2;
  }
}

@media only screen and (max-width: 479px) {
  .ticket-table-row {
    flex: none;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
  }
  .ticket-table-row-item {
    flex: none;
    width: 100%;
  }

  .ticket-table-row-item-ticket-control-button {
    width: 2.5rem;
    height: 2.5rem;
  }

  .ticket-table-row-item-ticket-control-tally {
    text-align: center;
  }

  .ticket-table-row-item--ticket-price {
    margin: 0.5rem 0 0 0;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    flex: 2;
  }
  .ticket-table-row-item--ticket-controls {
    width: 100%;
    justify-content: center;
    align-items: center;
    display: flex;
    margin-top: 0.5rem;
  }
  .ticket-table-row-item-ticket-buttons {
    width: 100%;
    justify-content: center;
    align-items: center;
    background: #333;
    padding: 0.25rem;
    border-radius: 1.5rem;
  }
  .ticket-table-row-item-ticket-remaining {
    text-align: center;
    bottom: -0.85rem;
  }
}
