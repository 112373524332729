.card-group {
  margin-bottom: 1.2rem;
}
.card-form-row {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.card-section {
  background-color: white;
  display: block;
  font-size: inherit;
  padding: 0.5rem 0.75rem;
  margin-bottom: 1.2rem;
  border-image: none;
  border-style: solid;
  border-width: 2px;
  border-color: #ddd;
  border-radius: 0.2rem;
  background-clip: padding-box;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.card-section--focus {
  box-shadow: 0 1px 3px 0 #cfd7df;
  border: 0.1rem solid rgba(218, 165, 32, 0.6); /*custom color*/
}

.card-section--invalid {
  border-color: #ff3323;
}

.card-section--webkit-autofill {
  background-color: #fefde5 !important;
}

.card-label {
  font-size: 0.8rem;
  display: inline-block;
  margin-bottom: 0.25rem;
}
.card-group--short {
  flex: 0 45%;
}
