.current-user-container {
  display: flex;
  justify-content: flex-start;
  flex: 1;
}

.current-user-content {
  flex: 1;
  text-align: left;
}

.current-user-name {
  font-size: 0.8rem;
  color: #666;
  margin-right: 0.2rem;
}

.current-user-logout {
  color: #333;
  border: 0;
  padding: 0;
  margin: 0;
  font-size: 0.8rem;
  letter-spacing: 0;
  cursor: pointer;
  background: none;
  font-family: inherit;
}
