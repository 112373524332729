.product-row {
  flex: 1 0 auto;
  display: flex;
  width: 100%;
  box-sizing: border-box;
  background: #111111;
  margin: 0.5rem 0;
  padding: 1rem 1rem;
  border-radius: 0.1rem;
  z-index: 0;
  justify-content: space-between;
  align-items: center;
}

.product-row:hover {
  cursor: pointer;
  background-color: #000000; /*custom color*/
  box-shadow: 0 0 0 0.1px rgba(50, 50, 93, 0.01),
    0 2px 5px rgba(50, 50, 93, 0.1), 0 1px 2px rgba(50, 50, 93, 0.05);
}

.product-row__image {
  flex: 0 0 auto;
  margin-right: 1rem;
}
.product-row__image img {
  width: 10vw;
}
.product-row__name {
  flex: 1 1 auto;
  text-align: left;
  font-family: 'axiformasemibold';
  font-size: 1.1rem;
  margin-right: 1rem;
}

.product-row__price {
  flex: 0 0 auto;
  margin-right: 3rem;
}

.product-row__controls {
  flex: 0 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.product-row__quantity {
  margin: 0 1.5rem;
  font-size: 1.5rem;
  font-family: 'axiformasemibold';
}

.product-row__control-button {
  letter-spacing: 0;
  background-color: ff9e1b;
  border: 0.1rem solid rgba(0, 0, 0, 0.6); /*custom color*/
  border-radius: 50%;
  box-shadow: none;
  box-sizing: border-box;
  padding: 0;
  width: 2.5rem;
  height: 2.5rem;
  color: rgba(0, 0, 0, 0.8); /*custom color*/
}

.product-row__control-button:hover {
  color: rgba(0, 0, 0, 1); /*custom color*/
  border: 0.1rem solid rgba(0, 0, 0, 0.8); /*custom color*/
}

.ticket-table-row-item-ticket-control-button--disabled {
  display: none;
}

.product-row--in-basket {
  background-color: rgba(0, 0, 0, 1); /*custom color*/
}

.product-row-details {
  display: flex;
  flex-direction: column;
  margin-right: 5rem;
}

.product-row__description {
  margin-top: 1rem;
}

@media only screen and (max-width: 630px) {
  .product-row {
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
  }
  .product-row-details {
    margin-right: 0;
  }
  .product-row__name {
    order: 1;
    flex: 1 0 70%;
    margin: 0 0 0.5rem 0;
  }
  .product-row__description {
    order: 2;
    margin: 0 0 1rem 0;
  }
  .product-row__price {
    order: 3;
    flex: 0 1 auto;
    margin-right: 0;
    margin: 0 0 1rem 0;
    font-family: 'axiformasemibold';
  }
  .product-row__image {
    order: 4;
    flex: 0 0 100%;
    margin-right: 0;
  }
  .product-row__image img {
    width: 100%;
    height: 100%;
  }
  .product-row__quantity {
    margin: 0 3rem;
  }
  .product-row__controls {
    flex: 1 1 auto;
    order: 4;
    margin: 1rem 0 0 0;
    justify-content: center;
  }
  .product-row__button {
    font-size: 2rem;
  }
}
