.content-card__image {
  width: 100%;
  display: flex;
  justify-content: center;
}

.content-card__image-image {
  width: 17rem;
  height: 17rem;
}

@media only screen and (max-width: 479px) {
  .content-card__image {
    width: 14rem;
    margin: 0 auto;
  }
  .content-card__image-image {
    width: 14rem;
    height: 14rem;
  }
}
