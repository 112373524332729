.basket-total-bar-total {
  font-size: 1rem;
  font-family: "axiformasemibold";
  flex: 3;
  display: flex;
  justify-content: flex-start;
  color: #111;
}

.basket-total-bar-total-item--fee,
.basket-total-bar-total-item--tickets {
  margin-left: 0.3rem;
}

.basket-total-cta {
  flex: 1 0 auto;
  display: flex;
  justify-content: flex-end;
}

@media screen and (min-width: 480px) and (max-width: 649px) {
  .basket-total-bar-total {
    font-size: 1rem;
    flex: 3;
    flex-direction: column;
  }
  .basket-total-cta {
    flex: 2;
  }
  .basket-total-bar-total-item {
    flex: 1;
  }
  .basket-total-bar-total-item--tickets {
    display: none;
  }
}

@media screen and (max-width: 479px) {
  .basket-total-bar-total {
    font-size: 1rem;
    flex: 1;
    flex-direction: column;
  }
  .basket-total-bar-total__total,
  .basket-total-bar-total-item--fee {
    display: none;
  }
  .basket-total-cta {
    flex: 2;
  }
  .basket-total-bar-total-item {
    flex: 1;
  }
  .basket-total-bar-total-item--fee {
    margin-left: 0;
  }
  .basket-total-bar-total-item--tickets {
    display: none;
  }
}
