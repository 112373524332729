.select-time-container {
  margin: 1rem 0 3rem 0;
  text-align: left;
}

.select-time-container-header {
  margin-top: 1rem;
  display: flex;
}

.select-time-options {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 2rem;
  justify-content: space-between;
  margin-left: -2%;
}

.select-time__specific-requirements {
  position: absolute;
  top: 0.8rem;
  right: 0.75rem;
  /*display: inline-block;
  vertical-align: text-bottom;*/
  width: 2.5rem;
  min-width: 2.5rem;
  max-width: 2.5rem;
  z-index: 100;
}

.select-time__specific-requirements--wheelchair {
  position: absolute;
  top: 1rem;
  right: 0.75rem;
  width: 1.25rem;
  min-width: 1.25rem;
  max-width: 1.25rem;
  z-index: 100;
}

.select-time__specific-requirements-icon {
  width: 100%;
}

/* New styles */

.time-button {
  flex: 1 0 48%;
  width: 48%;
  min-width: 48%;
  max-width: 48%;
  margin-left: 2%;
  color: #333;
  margin-bottom: 1rem;
  box-shadow: none;
  background: #f1f1f1;
  letter-spacing: 0;
  font-family: inherit;
  text-transform: none;
  border: none;
  position: relative;
  height: 3rem;
  padding: 0;
}

.time-button:hover {
  background-color: #eee;
  box-shadow: 0 0 0 0.1px rgba(50, 50, 93, 0.01),
    0 2px 5px rgba(50, 50, 93, 0.1), 0 1px 2px rgba(50, 50, 93, 0.05);
  color: #000;
}

.time-button-availability__indicator {
  height: 3rem;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.time-button-availability__label {
  font-size: 1rem;
  font-weight: 400;
  display: flex;
  flex-direction: row;
  text-align: center;
  z-index: 50;
  font-family: 'axiformasemibold', sans-serif;
}

.time-button-availability__indicator-bar {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  background-color: rgba(255, 158, 27, 0.3);
}
.time-button-availability__indicator-bar--sold-out {
  width: 100%;
  background-color: rgba(231, 31, 31, 0.3);
}

@media only screen and (max-width: 479px) {
  .select-time-options {
    margin-left: -2%;
  }
  .select-time {
    flex: 1 0 48%;
    width: 48%;
    min-width: 48%;
    max-width: 48%;
    margin-left: 2%;
  }
  .select-time:nth-child(even) {
    margin-right: 0;
  }
}
