textarea {
  resize: none;
}

.form__row-container {
  flex: 1 0 100%;
  display: flex;
  width: 100%;
}

.form__row-container--narrow {
  flex: 1 0 50%;
  max-width: 48%;
}

.form__row {
  margin-bottom: 1.2rem;
  display: flex;
  flex-wrap: wrap;
  flex: 1 0 100%;
  align-items: flex-end;
  position: relative;
  transition: box-shadow 0.15s ease-in-out;
}

.form__row--check {
  position: relative;
  display: block;
  padding-left: 1.25rem;
  padding-top: 1rem;
}

.form__label {
  font-size: 0.9rem;
  display: inline-block;
  margin: 0 0 0.5rem 0;
  color: #333;
}

.form__label--hidden {
  display: none;
}

.form__label--check {
  margin-bottom: 0;
}

.form__field-input {
  font-family: inherit;
  font-size: 1rem;
  padding: 0.5rem 0.75rem;
  border-image: none;
  border-style: solid;
  border-width: 0.1rem;
  border-color: #ddd;
  border-radius: 0.2rem;
  background-clip: padding-box;
  background-color: #fff;
  transition: border-color 0.15s ease-in-out;
  box-sizing: border-box;
  flex: 1 0 100%;
  max-width: 100%;
}

.form__field-input:focus {
  outline: 0;
  border-color: #666;
  background-color: #fff;
  box-shadow: 0 1px 3px 0 #cfd7df;
  border: 0.1rem solid rgba(0, 0, 0, 0.5); /*custom color*/
}
.form__field-input::placeholder {
  color: #999;
}

.form__field-input--error {
  border-color: #ff3323;
}

.form__field-input--check {
  position: absolute;
  margin-top: 0.25rem;
  margin-left: -1.25rem;
}

.form__error {
  color: #ff3323;
  font-size: 0.7rem;
  margin-top: 0.25rem;
  flex: 1 0 100%;
  position: absolute;
  bottom: -0.9rem;
  width: 100%;
  left: 0;
}

.form__field--label-after {
  margin-bottom: 0;
}

@media only screen and (max-width: 509px) {
  .form__row-container--narrow {
    flex: 1 0 100%;
    display: flex;
    width: 100%;
    max-width: 100%;
  }
  .form__field-input--check {
    position: absolute;
    margin-top: 0;
    margin-left: -1.2rem;
  }
}
