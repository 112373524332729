.mapbox {
  width: 100%;
}

.mapboxgl-canvas {
  position: relative;
  left: 0;
  width: 100%;
}

.mapbox-map {
  width: 100%;
}

.mapbox-control {
  position: absolute;
  left: 1rem;
  top: 1rem;
}

.mapbox-marker {
  font-size: 3rem;
}
