.footer-menu {
  justify-content: space-between;
  align-items: center;
  min-height: 5.5rem;
  height: 5.5rem;
  font-family: inherit;
  display: flex;
  width: 100%;
  font-size: 0.9rem;
}

.footer-menu__link {
  margin-left: 1.5rem;
  font-family: 'axiformasemibold';
  cursor: pointer;
}

.footer-menu__link--special {
  margin-left: 1.5rem;
  font-family: 'axiformasemibold';
  cursor: pointer;
  /* background: #daa520;
  color: #333333; */
  border: 1px solid #daa520;
  padding: 0.25rem 0.5rem;
  border-radius: 2px;
}

/* .footer-menu__link--special::before {
  content: 'NEW';
  color: red;
  font-size: 0.75rem;
  margin-right: 0.5rem;
} */

.footer-menu__link--active {
  border-bottom: 1px solid;
}

.footer-menu__link:first-child {
  margin-left: 0;
}

.footer-menu__link:last-child {
  margin-right: 0.5rem;
}

.footer-menu__button {
  margin-left: auto;
}

.footer-menu__book {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.footer-menu__safe-icon {
  margin-right: 1rem;
  height: 44px;
}

.footer-menu__safe-icon-image {
  height: 44px;
}

@media only screen and (max-width: 975px) {
  .footer-menu__link {
    margin-left: 1.15rem;
    font-size: 0.75rem;
  }
}

@media only screen and (max-width: 630px) {
  .footer-menu__button {
    width: 100%;
  }
  .footer-menu__link {
    display: none;
  }
  .footer-menu__link--special {
    display: none;
  }
}
