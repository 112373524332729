.basket-summary {
}
.basket-summary__title {
  font-size: 1rem;
  font-family: "axiformasemibold";
  margin-bottom: 0.75rem;
  color: #333;
}

.basket-summary__main-content {
  border-radius: 2px;
  background-color: rgba(0, 0, 0, 0.9); /*custom color*/
  padding: 1rem;
  box-sizing: border-box;
}

.basket-summary__header {
  margin-bottom: 0.5rem;
  display: flex;
  justify-content: space-between;
}

.basket-summary__sub-title {
  font-size: 0.9rem;
  font-family: "axiformasemibold";
}

.basket-summary__edit-button {
  font-size: 0.8rem;
  color: #ff9e1b;
  border: 0;
  letter-spacing: 0;
  box-shadow: none;
  padding: 0;
  text-transform: none;
  background-color: transparent;
  font-family: "axiformasemibold";
}

.basket-summary__edit-button:focus {
  outline: 0;
}

.basket-summary__edit-button--wolf {
  color: #0f3e84;
}

.basket-summary__section {
  margin: 0;
  padding: 0.5rem 0;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
}
.basket-summary__section:last-child {
  border-bottom: none;
}

.basket-summary__section-title {
  font-size: 1rem;
  font-family: "axiformasemibold";
  margin-bottom: 0.75rem;
  margin-top: 0.5rem;
  display: block;
}

.basket-summary__section-details {
  margin-bottom: 1.5rem;
}

.basket-summary__event:last-child {
  margin-bottom: 0.75rem;
}

.basket-summary__event-date {
  font-size: 0.8rem;
  color: #999;
  display: block;
}

.basket-summary__line-item {
  display: flex;
  flex: 1;
  margin: 0.75rem 0;
  justify-content: space-between;
  font-size: 1rem;
}
.basket-summary__discount-code {
  font-family: "axiformasemibold";
}
.basket-summary__line-item--total {
  font-family: "axiformasemibold";
}
.basket-summary__note {
  font-size: 0.7rem;
  color: #666;
  padding-top: 1rem;
}
