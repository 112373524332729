.timer-container {
  display: flex;
  flex: 1;
}

.timer {
  flex: 1;
  text-align: right;
  font-size: 1.5rem;
  color: #333;
  font-family: "axiformasemibold";
}

@media screen and (max-width: 479px) {
}
