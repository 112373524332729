.order-success-container {
  text-align: left;
  margin-bottom: 5rem;
  color: #0a0a0a;
}

.order-success-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.booking-social {
  margin-top: 2rem;
  display: flex;
  justify-content: flex-start;
}

.booking-social--center {
  justify-content: center;
}

.booking-social-button {
  margin-right: 1rem;
}

.booking-social-button:last-child {
  margin-right: 0;
}

.social-icon {
  margin-right: 0.3rem;
}

.success-wolf-account {
  text-align: center;
  margin: 2rem 0 3rem 0;
  background-color: #f9ffd9;
  font-family: "Montserrat", serif;
  font-size: 1.2rem;
  color: #333;
  padding: 2rem 2rem 4rem 2rem;
}

@media only screen and (max-width: 479px) {
  .order-success-header {
    flex-direction: column;
  }
  .download-action {
    margin-top: 1rem;
    text-align: center;
  }
  .booking-social {
    flex-direction: column;
  }
  .booking-social-button {
    margin: 0 0 1rem 0;
    flex: 1;
    width: 92%;
  }
  .order-success-container {
    text-align: left;
    margin-bottom: 0;
  }
}
